import { Component, OnInit } from '@angular/core';
import { ContractService } from '../services/contract.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-hr-edit',
  templateUrl: './hr-edit.component.html',
  styleUrls: ['./hr-edit.component.css']
})
export class HrEditComponent implements OnInit {
  id;
  contract;
  uniformsFullFrom: FormGroup;
  constructor(private contractService: ContractService, private route: ActivatedRoute, private fb: FormBuilder) {

   }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.id = this.route.snapshot.paramMap.get("id");
    this.contractService.getContract(this.id).subscribe(data => {
      this.contract = data;
      this.uniformsFullFrom = this.fb.group({
        'full_uniforms_count': [this.contract.full_uniforms_count]
      })
    })
  }

  sendUniformFull() {
    this.contractService.editContract(this.id, this.uniformsFullFrom.value).subscribe(data => {
      this.contract = data;
      this.uniformsFullFrom.reset();
    })
  }

}
