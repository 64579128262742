import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ContractService } from '../services/contract.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

const path = environment.apiUrl;

@Component({
  selector: 'app-accounting-edit',
  templateUrl: './accounting-edit.component.html',
  styleUrls: ['./accounting-edit.component.css']
})
export class AccountingEditComponent implements OnInit {
  contract;
  contractForm: FormGroup;
  pdfForm: FormGroup;
  user_role: string;
  id: string;
  company_names;
  cities;
  iframeSrc;
  base64;
  spinner=false;


  constructor(private contractService: ContractService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef,
    private router: Router) {
      this.user_role = localStorage.getItem('role');
      this.id = this.route.snapshot.paramMap.get("id")

    this.loadContract()
  }

  ngOnInit() {
  }

  loadContract() {
    this.contractService.getContract(this.id).subscribe(data => {
      this.contract = data;
      this.contractService.getCommonConstants().subscribe(data => {
        this.company_names = data['company_names'];
        this.cities = data['cities'];
      })
      this.contractForm = this.formBuilder.group({
        'company_name': [this.contract.company_name,],
        'city': [this.contract.city,],
        'name': [this.contract.name,],
        'start_date': [this.contract.start_date,],
        'end_date': [this.contract.end_date,],
        'commission_percent': [this.contract.commission_percent,],
        'income_security_guards': [this.contract.income_security_guards,]
      })
      this.pdfForm = this.formBuilder.group({
        'pdf': ['']
      })
    })
  }

  deletePDF() {
    if (confirm("Сигурни ли сте, че искате да изтриете файла към договора?")) {

      this.contractService.deletePDFFile(this.contract.id).subscribe(data => {
        this.loadContract();

      })

    }

  }

  sendContract() {
    this.contractService.editContract(this.id, this.contractForm.value).subscribe(a => {
      this.contractForm.reset();
      this.router.navigate(['contracts', this.id, 'edit'])
    })
  }

  sendPdf() {
    this.spinner = true;

    this.contractService.editContract(this.id, {"pdf": this.base64}).subscribe(a => {

      this.pdfForm.reset();
      this.spinner = false;
      this.loadContract()

      // this.router.navigate(['contracts', this.id, 'edit'])
    })

  }


  onFileChange(event) {
    let reader = new FileReader();
    console.log("reader")
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      console.log(file)

      reader.onload = () => {

        this.base64 = reader.result;

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

}
