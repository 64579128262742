import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

const path = environment.apiUrl;
const loginUrl = path + 'accounts/login/'

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private http: HttpClient) { }

  login(data) {
    return this.http.post(loginUrl, data);
  }
}
