import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContractService } from '../services/contract.service';

@Component({
  selector: 'app-partial-sets-info',
  templateUrl: './partial-sets-info.component.html',
  styleUrls: ['./partial-sets-info.component.css']
})
export class PartialSetsInfoComponent {
  contract;
  setPartialForm: FormGroup;
  user_role: string;
  id: string;

  names;
  kinds;
  models;

  constructor(private contractService: ContractService, 
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router:  Router) { 
      this.user_role = localStorage.getItem('role');
      this.id = this.route.snapshot.paramMap.get("id")
      this.setPartialForm = this.formBuilder.group({
        'product': ['',],
        'kind': ['',],
        'model': ['',],
        'quantity': ['',],
        'price': ['',],
        'contract_object_id': [Number(this.id)],

      })
      this.contractService.getContract(this.id).subscribe(data => {
        this.contract = data;
      })    
  }

  sendSetPartial() {
    console.log(this.setPartialForm.value)
    this.contractService.editContract(this.id, {"security_equipments": [this.setPartialForm.value]}).subscribe(contract => {
      this.contract = contract;
      location.reload()
    })
  }
}