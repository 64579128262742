import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ContractService } from '../services/contract.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-guard-edit',
  templateUrl: './guard-edit.component.html',
  styleUrls: ['./guard-edit.component.css']
})
export class GuardEditComponent {
  contract;
  contractForm: FormGroup;
  user_role: string;
  idGuard: string;
  id;
  contracts;
  guard;
  respondent: boolean = false;

  constructor(private contractService: ContractService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router) {
      this.user_role = localStorage.getItem('role');
      this.idGuard = this.route.snapshot.paramMap.get("idGuard");
      this.id = this.route.snapshot.paramMap.get("id");
      this.contractService.getGuard(this.idGuard).subscribe(data => {
        this.contractService.getCommonConstants().subscribe(data => {
          console.log(data)
          this.contracts = data["contracts"];
          console.log(this.contracts)
        })
        this.guard = data;
        this.respondent = this.guard.is_respondent;

        this.contractForm = this.formBuilder.group({
          'first_name': [this.guard.first_name,],
          'second_name': [this.guard.second_name,],
          'last_name': [this.guard.last_name,],
          'salary': [this.guard.salary,],
          'insurance': [this.guard.insurance,],
          'identification_number': [this.guard.identification_number,],
          'start_date': [this.guard.start_date],
          'end_date': [this.guard.end_date],
          'full_uniforms_count': [this.guard.full_uniforms_count],
          'contract_object': [this.guard.contract_object],
          'is_respondent': [this.guard.is_respondent]
        })
      })
  }

  makeRespondent(event) {
    event.target.checked ? this.respondent = true  :  this.respondent = false;
  }

  sendContract() {
    this.contractForm.patchValue({'is_respondent': this.respondent});

   this.contractService.editGuard(this.guard.id,this.contractForm.value).subscribe(contract => {
     this.contractForm.reset();
     this.respondent = false;
     this.contract = contract
     this.router.navigate(['contracts', this.id, 'hr'])
   })
  }


}
