import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ContractService } from '../services/contract.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-monthly-expense',
  templateUrl: './monthly-expense.component.html',
  styleUrls: ['./monthly-expense.component.css']
})
export class MonthlyExpenseComponent implements OnInit {
  contract;
  contractForm: FormGroup;
  user_role: string;
  id: string;
  expenses;

  constructor(private contractService: ContractService, 
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService) { 
      this.user_role = localStorage.getItem('role');
      this.id = this.route.snapshot.paramMap.get("id")
      this.contractService.getContract(this.id).subscribe(data => {
        this.contract = data;
        this.contractForm = this.formBuilder.group({
          'name': ['',],
          'period': ['',],
          'amount': ['',]
        })
      })
     
  }

  ngOnInit() {
    this.contractService.getCommonConstants().subscribe(data => {
      this.expenses = data['expenses'];
      console.log(this.expenses)
    })
  }

  sendContract() {
    console.log(this.contractForm.value)
    this.contractService.editContract(this.id, {"monthly_expenses": [this.contractForm.value]}).subscribe(a => {
      this.contractForm.reset();
    })
  }

  changeName(data) {
    this.contractForm.patchValue({'name': data});
    console.log(this.contractForm.value)
  }
}
