import { Component, OnInit, Input } from '@angular/core';
import { ContractService } from '../services/contract.service';

@Component({
  selector: 'app-list-partial-sets',
  templateUrl: './list-partial-sets.component.html',
  styleUrls: ['./list-partial-sets.component.css']
})
export class ListPartialSetsComponent implements OnInit {
  @Input('sets')
  sets;
  constructor(private contractService: ContractService) {
   }

  ngOnInit() {
  }

  deleteSet(id) {
    this.contractService.deleteSet( id).subscribe(data => {
      console.log(data);
      location.reload()
    })
  }

}
