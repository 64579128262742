import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractService } from '../services/contract.service';

@Component({
  selector: 'app-list-partial-uniforms',
  templateUrl: './list-partial-uniforms.component.html',
  styleUrls: ['./list-partial-uniforms.component.css']
})
export class ListPartialUniformsComponent implements OnInit {
  @Input('uniforms') guards;
  uniforms;
  contractID;
  idGuard;
  constructor(private route: ActivatedRoute, private contractService: ContractService) {
    // this.contractID = this.route.snapshot.paramMap.get("id");


   }

  deleteClothes(clothesId) {
    if (confirm("Сигурни ли сте, че искате да изтриете тази униформа?")) {

      this.contractService.deleteClothes(clothesId).subscribe(data => {
      window.location.reload();
    })
    }
  }

  ngOnInit() {
    if (!this.guards) {
      console.log('ot PARTIAL UNIFORM if')
      this.idGuard = this.route.snapshot.paramMap.get("idGuard");
      this.contractService.getGuard(this.idGuard).subscribe(g => {
        console.log(g)
        this.uniforms = g['uniforms_partial']
        console.log('ot PARTIAL UNIFORM if')
      })

    }
    else {
      this.uniforms = this.guards.filter(g => g.id == this.idGuard)
      console.log('ima guards')
    }
  }

}
