import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ContractService } from '../services/contract.service';

@Component({
  selector: 'app-create-contract',
  templateUrl: './create-contract.component.html',
  styleUrls: ['./create-contract.component.css']
})
export class CreateContractComponent implements OnInit {
  contractForm: FormGroup;
  company_names;
  cities;
  spinner=false;

  constructor(private formBuilder: FormBuilder,
    private router: Router, private cd: ChangeDetectorRef,
    private contractService: ContractService,
) {
      this.contractForm = this.formBuilder.group({
        'company_name': ['',],
        'city': ['',],
        'name': ['',],
        'start_date': ['',],
        'end_date': ['',],
        'commission_percent': ['',],
        'income_security_guards': [''],
        'pdf': ['']
      })
    }

  ngOnInit() {
    this.contractService.getCommonConstants().subscribe(data => {
      this.company_names = data['company_names'];
      this.cities = data['cities'];
    })
  }

  sendContract() {
  this.spinner = true;

    this.contractService.createContract(this.contractForm.value).subscribe(res => {
      this.contractForm.reset();
      this.spinner = false;
      this.router.navigate(['contracts'])
    })

  }

  onFileChange(event) {
    let reader = new FileReader();

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.contractForm.patchValue({
          pdf: reader.result
        });

        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

}
