import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  error;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthenticationService) {
    this.loginForm = this.formBuilder.group({
      'username': ['',],
      'password': ['',]
    })
   }

  ngOnInit() {
  }

  login() {
    this.authService.login(this.loginForm.value).subscribe(data => {
      localStorage.setItem('token', data['key']);
      localStorage.setItem('role', data['role'])
      this.router.navigate(['contracts'])
    }, err => {
      this.error = err;
    })
  }

}
