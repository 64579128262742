import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-list-monthly-expense',
  templateUrl: './list-monthly-expense.component.html',
  styleUrls: ['./list-monthly-expense.component.css']
})
export class ListMonthlyExpenseComponent implements OnInit {
  @Input('expenses')
  expenses;
  constructor() { }

  ngOnInit() {
  }

}
