import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

const path = environment.apiUrl;
const contratactURL = path + 'contracts/'
@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(private http: HttpClient) { }

  createContract(data) {
    return this.http.post(contratactURL, data);
  }

  deletePDFFile(id) {
    return this.http.delete(contratactURL + "pdf/" + id + "/");
  }

  getContracts(page=0) {
    return this.http.get(contratactURL+"?page=" + page);
  }

  getExpiringContracts() {
    return this.http.get(contratactURL+"expiring/");

  }

  getImage(urlImage): Observable<Blob> {
    return this.http
      .get(urlImage, {
        responseType: "blob"
      });
  }

  getGuard(id) {
    //here changed with edit
    return this.http.get(contratactURL + 'guard/' + id + '/edit/')
  }

  deleteGuard(id) {
      return this.http.delete(contratactURL + 'guard/' + id + '/')

  }


  getContract(id) {
    return this.http.get(contratactURL + id + '/')
  }

  editContract(id, data) {
    return this.http.patch(contratactURL + id  + '/', data)
  }

  deleteContract(id) {
    return this.http.delete(contratactURL + id  + '/')

  }

  exportToExel() {
    return this.http.get(contratactURL + 'export/xls/');
  }

  createGuard(data) {
    return this.http.post(contratactURL + 'guards/' , data)
  }

  editGuard(id, data) {
    return this.http.patch(contratactURL + 'guard/' + id  + '/', data)
  }

  getCommonConstants() {
    return this.http.get(contratactURL + 'commons/')
  }

  getTupleNames() {
    return this.http.get(contratactURL + 'tuplenames/')
  }

  getTupleKinds() {
    return this.http.get(contratactURL + 'tuplekinds/')
  }

  getTupleModels() {
    return this.http.get(contratactURL + 'tuplemodels/')
  }

  createTupleName(data) {
    return this.http.post(contratactURL + 'tuplenames/', data)
  }

  createKind(data) {
    return this.http.post(contratactURL + 'tuplekinds/', data)
  }

  createModel(data) {
    return this.http.post(contratactURL + 'tuplemodels/', data)
  }

  deleteInventory(id) {
    return this.http.delete(contratactURL + 'inventory/'+id+'/');
  }

  deleteClothes(id) {
    return this.http.delete(contratactURL + 'uniforms/'+id+'/');

  }

  deleteSet(id) {
    return this.http.delete(contratactURL + 'equipment/'+id+'/');
  }

}
