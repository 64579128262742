import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractService } from '../services/contract.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  id;
  contract;
  constructor(private route: ActivatedRoute, private router: Router, 
    private contractService: ContractService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.contractService.getContract(this.id).subscribe(data => {
      this.contract = data
    })
  }

  routeTo(data) {
    this.router.navigate(['contracts', this.id, data]);
  }

}
