import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractService } from '../services/contract.service';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

const path = environment.apiUrl;

@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.css']
})
export class ContractDetailsComponent implements OnInit {
  id;
  contract;
  role;
  pdfSrc = path;
  image;
  fileUrl;
  iframeSrc;
  company_name_cyrilic;
  city_cyrilic;

  constructor(private route: ActivatedRoute, private contractService: ContractService,
    private sanitizer: DomSanitizer) { }

  deletePDF() {
    if (confirm("Сигурни ли сте, че искате да изтриете файла към договора?")) {

      this.contractService.deletePDFFile(this.contract.id).subscribe(data => {
        this.loadContract()

      })

    }

  }

  loadContract() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.role = localStorage.getItem('role')
    this.contractService.getContract(this.id).subscribe(c => {
      this.contract = c;
      this.pdfSrc = this.pdfSrc + this.contract.pdf.replace("/", "")
      this.contract.contract_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.contract.contract_url)

    })
  }

  ngOnInit() {
  this.loadContract()

  }

}
