import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ContractService } from '../services/contract.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-partial-uniforms-info',
  templateUrl: './partial-uniforms-info.component.html',
  styleUrls: ['./partial-uniforms-info.component.css']
})
export class PartialUniformsInfoComponent {
  contract;
  uniformPartialForm: FormGroup;
  user_role: string;
  id: string;
  guards;
  commons

  constructor(private contractService: ContractService, 
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private router: Router) { 
      this.user_role = localStorage.getItem('role');
      this.id = this.route.snapshot.paramMap.get("id")
      this.uniformPartialForm = this.formBuilder.group({
        'name': ['',],
        'count': ['',],
        'amount': ['',],
        'guard_id': ['']
      })
     this.contractService.getCommonConstants().subscribe(constData => {
       this.commons = constData['uniforms']
      this.contractService.getContract(this.id).subscribe(data => {
        this.contract = data;
        this.guards = this.contract.guards.filter(g => g.contract_object == this.id);
      })})
    
  }

  sendUniformPartial() {
    this.contractService.editGuard(this.uniformPartialForm.get('guard_id').value, {'uniforms_partial': [this.uniformPartialForm.value]}).subscribe(contract => {
      this.contract = contract;
      this.uniformPartialForm.reset();
      
    })
  }
}
