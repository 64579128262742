import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateContractComponent } from './create-contract/create-contract.component';
import { ContractService } from './services/contract.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContractsComponent } from './contracts/contracts.component';
import { PreCreateContractComponent } from './pre-create-contract/pre-create-contract.component';
import { EditComponent } from './edit/edit.component';
import { AccountingEditComponent } from './accounting-edit/accounting-edit.component';
import { AccountingFuardService } from './services/accounting-fuard.service';
import { ScaleGuardService } from './services/scale-guard.service';
import { NoPermissionComponent } from './no-permission/no-permission.component';
import { HrGuardService } from './services/hr-guard.service';
import { SotOpeeratorGuardService } from './services/sot-opeerator-guard.service';
import { HrEditComponent } from './hr-edit/hr-edit.component';
import { SotEditComponent } from './sot-edit/sot-edit.component';
import { MonthlyExpenseComponent } from './monthly-expense/monthly-expense.component';
import { GuardInfoComponent } from './guard-info/guard-info.component';
import { ListGuardsComponent } from './list-guards/list-guards.component';
import { ListInventoryComponent } from './list-inventory/list-inventory.component';
import { InventoryInfoComponent } from './inventory-info/inventory-info.component';
import { ListPartialSetsComponent } from './list-partial-sets/list-partial-sets.component';
import { ListPartialUniformsComponent } from './list-partial-uniforms/list-partial-uniforms.component';
import { PartialUniformsInfoComponent } from './partial-uniforms-info/partial-uniforms-info.component';
import { PartialSetsInfoComponent } from './partial-sets-info/partial-sets-info.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ContractDetailsComponent } from './contract-details/contract-details.component';
import { ListMonthlyExpenseComponent } from './list-monthly-expense/list-monthly-expense.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { ToastrModule  } from 'ngx-toastr';
import { GuardEditComponent } from './guard-edit/guard-edit.component';
import { CreateDualWordsComponent } from './create-dual-words/create-dual-words.component';
import { CreateExelComponent } from './create-exel/create-exel.component';
import { HrFlowComponent } from './hr-flow/hr-flow.component';
import { SpinnersAngularModule } from 'spinners-angular';



@NgModule({
  declarations: [
    AppComponent,
    CreateContractComponent,
    ContractsComponent,
    PreCreateContractComponent,
    EditComponent,
    AccountingEditComponent,
    NoPermissionComponent,
    HrEditComponent,
    SotEditComponent,
    MonthlyExpenseComponent,
    GuardInfoComponent,
    ListGuardsComponent,
    ListInventoryComponent,
    InventoryInfoComponent,
    ListPartialSetsComponent,
    ListPartialUniformsComponent,
    PartialUniformsInfoComponent,
    PartialSetsInfoComponent,
    NavbarComponent,
    ContractDetailsComponent,
    ListMonthlyExpenseComponent,
    GuardEditComponent,
    CreateDualWordsComponent,
    CreateExelComponent,
    HrFlowComponent
  ],
  imports: [
    BrowserModule,
    SpinnersAngularModule  ,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    PdfViewerModule,
    AuthenticationModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers:  [ { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    ContractService,
  AccountingFuardService,
  ScaleGuardService,
  SotOpeeratorGuardService,
  HrGuardService
],
  bootstrap: [AppComponent]
})
export class AppModule { }
