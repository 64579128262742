import { Component, OnInit } from '@angular/core';
import { ContractService } from '../services/contract.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.css']
})
export class ContractsComponent implements OnInit {
  contracts;
  expiringContracts;
  role;
  page = 1;
  pages: any;

  constructor(private contractService: ContractService, private router: Router) {
    this.role = localStorage.getItem('role');
    this.loadContracts();

  }

  changePage(pNumber=null, prev=null, next=null) {
    if (pNumber != null) {
      this.page = pNumber;
    }
    else if (prev != null) {
      this.page -= 1
    }
    else {
      this.page +=1
    }
    this.loadContracts()
  }

  ngOnInit() {




  }

  loadExpiting() {
    console.log('req')
    this.contractService.getExpiringContracts().subscribe(contracts => {

      this.expiringContracts =  contracts['contracts'];
      console.log(contracts)

    })
  }

  loadContracts() {
    this.contractService.getContracts(this.page).subscribe(contracts => {
      this.pages = contracts['pages'];
      this.loadExpiting();

      if (this.router.url.includes('archived')) {
        this.contracts = contracts['contracts'];
        this.contracts = this.contracts.filter(con => con.is_archived == true);
      }
      else {
        this.contracts = contracts['contracts'];
        this.contracts = this.contracts.filter(con => con.is_archived == false);

      }
    })
  }

  archiveContract(id) {
    this.contractService.editContract(id, {'is_archived': true}).subscribe(data => {
      this.contracts = data;
      this.router.navigate(['/contracts/archived'])
    })
  }

  unarchiveContract(id) {
    this.contractService.editContract(id, {'is_archived': false}).subscribe(data => {
      this.contracts = data;
      this.router.navigate(['/contracts'])
    })
  }

  dedleteContract(id) {
    if(confirm("Сигурни ли сте че искате да изтриете договора?")) {
      this.contractService.deleteContract(id).subscribe(data => {
        this.loadContracts()
      })
    }

  }



}
