import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ContractService } from '../services/contract.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-list-guards',
  templateUrl: './list-guards.component.html',
  styleUrls: ['./list-guards.component.css']
})
export class ListGuardsComponent implements OnChanges {
  // this is used when component is child
  @Input()
  guards;
  // This is used when component is self stending (SS)
  id;
  contractID;
  link;


  guardsToList;
  constructor(private contractService: ContractService, private route: ActivatedRoute) {
    if (this.guards) {
      this.contractID = this.guards[0].contract_object;
      this.link = "http://127.0.0.1:8000/contracts/export/xls/" + this.contractID+ "/hr/"
    }
    else {
      this.id = this.route.snapshot.paramMap.get("id");
      this.loadGuards()

    }
   }

   ngOnChanges(changes: SimpleChanges) {
    this.guardsToList = this.guards;
    this.contractID = this.guards[0].contract_object;
      this.link = "http://127.0.0.1:8000/contracts/export/xls/" + this.contractID+ "/hr/"
  }

  deleteGuard(guardID) {
    if (confirm("Сигурни ли сте, че искате да изтриете този охранител?")) {

      this.contractService.deleteGuard(guardID).subscribe(data => {
          this.loadGuards()

    })
    }
  }

  loadGuards() {
    this.contractService.getContract(this.id).subscribe(data => {
      console.log('guards zaqvka', data)
      this.contractID = data['id']
      this.guards = data['guards']
      console.log('guards zaqvka', this.guards)

    })
  }

  filterGuards(param) {
    if (param == 'all') {
      this.guardsToList = this.guards;
    }
    else if (param == 'working') {
      this.guardsToList = this.guards.filter(g => g.end_date == null);
    }
    else if (param == 'not-working') {
      this.guardsToList = this.guards.filter(g => g.end_date != null);
    }
  }

}
