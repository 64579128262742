import { Component, OnInit } from '@angular/core';
import { ContractService } from '../services/contract.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-pre-create-contract',
  templateUrl: './pre-create-contract.component.html',
  styleUrls: ['./pre-create-contract.component.css']
})
export class PreCreateContractComponent {
  contract;
  contractForm: FormGroup;
  user_role: string;
  id: string;

  constructor(private contractService: ContractService, 
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder) { 
      this.user_role = localStorage.getItem('role');
      this.id = this.route.snapshot.paramMap.get("id")
      this.contractService.getContract(this.id).subscribe(data => {
        this.contract = data;
        this.contractForm = this.formBuilder.group({
          'expense_salary_month': [this.contract.expense_salary_month,],
          'expense_insurances': [this.contract.expense_insurances,],
        })
      })
    
  }

  sendContract() {
    this.contractService.editContract(this.id, this.contractForm.value).subscribe(a => {
      this.contractForm.reset();
      this.router.navigate(['contracts', this.id, 'edit'])
    })
  }
}
