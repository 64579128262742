import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContractService } from '../services/contract.service';

@Component({
  selector: 'app-inventory-info',
  templateUrl: './inventory-info.component.html',
  styleUrls: ['./inventory-info.component.css']
})
export class InventoryInfoComponent {
  contract;
  contractForm: FormGroup;
  user_role: string;
  id: string;
  inventory;

  constructor(private contractService: ContractService, 
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) { 
      this.user_role = localStorage.getItem('role');
      this.id = this.route.snapshot.paramMap.get("id")
      this.contractForm = this.formBuilder.group({
        'name': ['',],
        'count': ['',],
      })
      this.contractService.getContract(this.id).subscribe(data => {
        this.contract = data;
        this.contractService.getCommonConstants().subscribe(data => {
          this.inventory = data['inventory'];
        })      
      })
    
  }

  sendContract() {
    this.contractService.editContract(this.id, {"inventories": [this.contractForm.value]}).subscribe(contract => {
      this.contract = contract;
      this.contractForm.reset();
      location.reload();
    })
  }
}

