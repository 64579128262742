import { Component, OnInit } from '@angular/core';
import { ContractService } from '../services/contract.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-dual-words',
  templateUrl: './create-dual-words.component.html',
  styleUrls: ['./create-dual-words.component.css']
})
export class CreateDualWordsComponent implements OnInit {

  constructor(private contractServise: ContractService, private _location: Location) { }

  ngOnInit() {
  }

  createName(product) {
    this.contractServise.createTupleName({'word': product.value}).subscribe(data => {
    })
    product.value = ''
  }

  createKind(kind) {
    this.contractServise.createKind({'word': kind.value}).subscribe(data => {
    })
    kind.value = ''
  }

  createModel(model) {
    this.contractServise.createModel({'word': model.value}).subscribe(data => {
    })
    model.value = ''
  }

  goBack() {
    this._location.back();
  }

}
