import { Component, OnInit } from '@angular/core';
import { ContractService } from '../services/contract.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isLogedOut = false;
  constructor(private contractService: ContractService, private router: Router) { }

  ngOnInit() {
  }

  exelExport() {
    this.contractService.exportToExel().subscribe(a => {
    })
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    this.isLogedOut = true;
    this.router.navigate(['login'])
  }

}
