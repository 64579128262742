import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ContractService } from '../services/contract.service';
import { environment } from '../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-exel',
  templateUrl: './create-exel.component.html',
  styleUrls: ['./create-exel.component.css']
})
export class CreateExelComponent implements OnInit {
  role;
  contractForm: FormGroup;
  hrForm: FormGroup;
  sotForm: FormGroup;
  company_names;
  cities;
  urlExel;
  contracts;
  exelChoice;
  clothesForm: FormGroup;
  equipmentForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private contractService: ContractService,
    private router: Router) {
      this.contractForm = this.formBuilder.group({
        'company_name': ['',],
        'city': ['',],
        'kind': [''],
      })

      this.hrForm = this.formBuilder.group({
        'contract_id': ['',],
        'end_date': ['',],
      })

      this.sotForm = this.formBuilder.group({
        'contract_id': ['',],
      })

      this.clothesForm = this.formBuilder.group({
        'contract_id': ['',],
        'end_date': ['',],
      })

      this.equipmentForm = this.formBuilder.group({
        'contract_id': ['',],
      })
    }

  ngOnInit() {
    this.role = localStorage.getItem('role')
    this.contractService.getCommonConstants().subscribe(data => {
      this.company_names = data['company_names'];
      this.cities = data['cities'];
      this.contractService.getContracts().subscribe(data => {
        this.contracts = data;
      })
    })
  }

  generateExelAccounting() {
    this.urlExel = environment.apiUrl + 'contracts/' + 'export/xls/?company_name=' +
      this.contractForm.get('company_name').value + '&city=' + this.contractForm.get('city').value +
      '&kind=' +   this.contractForm.get('kind').value
  }

  generateExelHR() {
    this.urlExel = environment.apiUrl + 'contracts/' + 'export/xls/'+
      this.hrForm.get('contract_id').value + '/hr/?end_date=' + this.hrForm.get('end_date').value + '&contract_id=' +  this.hrForm.get('contract_id').value
  }

  generateExelSOT() {
    this.urlExel = environment.apiUrl + 'contracts/' + 'export/xls/'+
    this.sotForm.get('contract_id').value + '/sot/'
  }

  selectExel(data) {
    this.exelChoice = data;
  }

  generateClothesExcel() {
    console.log(this.urlExel = environment.apiUrl + 'contracts/' + 'export/xls/'+
    this.clothesForm.get('contract_id').value + '/guards-clothes/?end_date=' + this.clothesForm.get('end_date').value + '&contract_id=' +  this.clothesForm.get('contract_id').value)
    this.urlExel = environment.apiUrl + 'contracts/' + 'export/xls/'+
    this.clothesForm.get('contract_id').value + '/guards-clothes/?end_date=' + this.clothesForm.get('end_date').value + '&contract_id=' +  this.clothesForm.get('contract_id').value
  }

  generateEquipmrntExcel() {
    this.urlExel = environment.apiUrl + 'contracts/' + 'export/xls/'+
    this.equipmentForm.get('contract_id').value + '/guards-equipments/'
  }

}
