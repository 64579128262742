import { Injectable } from '@angular/core';
import {  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError  } from 'rxjs';
import { map, catchError, tap} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private toastrService: ToastrService, private router: Router) { }

  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var token = localStorage.getItem('token')
    if (token) {
      return next.handle(
        req.clone({
          headers: req.headers.append('Authorization', 'Token ' + token)
        })
      ).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && event.status !== 200) {
            this.toastrService.success("Успешно създаден/записан");
          }
          if (event instanceof HttpErrorResponse && event.status === 400) {
            this.toastrService.error("Неуспех");
          }
        }), catchError((error: HttpErrorResponse) => {
          if (error.status == 401) {
            this.router.navigate(['login'])
          }
          else {
            this.toastrService.error("Неуспех");
          }
          return throwError(error);
        })
      )
    }
    return next.handle(req)
  }
}
