import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './authentication/login/login.component';
import { CreateContractComponent } from './create-contract/create-contract.component';
import { ContractsComponent } from './contracts/contracts.component';
import { PreCreateContractComponent } from './pre-create-contract/pre-create-contract.component';
import { ScaleGuardService } from './services/scale-guard.service';
import { EditComponent } from './edit/edit.component';
import { AccountingEditComponent } from './accounting-edit/accounting-edit.component';
import { AccountingFuardService } from './services/accounting-fuard.service';
import { NoPermissionComponent } from './no-permission/no-permission.component';
import { HrGuardService } from './services/hr-guard.service';
import { SotOpeeratorGuardService } from './services/sot-opeerator-guard.service';
import { HrEditComponent } from './hr-edit/hr-edit.component';
import { SotEditComponent } from './sot-edit/sot-edit.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ContractDetailsComponent } from './contract-details/contract-details.component';
import { GuardEditComponent } from './guard-edit/guard-edit.component';
import { CreateDualWordsComponent } from './create-dual-words/create-dual-words.component';
import { CreateExelComponent } from './create-exel/create-exel.component';
import { HrFlowComponent } from './hr-flow/hr-flow.component';
import { GuardInfoComponent } from './guard-info/guard-info.component';
import { InventoryInfoComponent } from './inventory-info/inventory-info.component';
import { ListGuardsComponent } from './list-guards/list-guards.component';
import { ListInventoryComponent } from './list-inventory/list-inventory.component';


const routes: Routes = [
  {path: '',
  redirectTo: '/contracts',
  pathMatch: 'full'
},
  {component: LoginComponent, path: 'login'},
  {component: CreateExelComponent, path: 'create-exel', canActivate: [AuthGuardService]},
  {component: CreateContractComponent, path: 'create-contract', canActivate: [AuthGuardService, AccountingFuardService]},
  {component: CreateDualWordsComponent, path: 'create-dual-words', canActivate: [AuthGuardService, SotOpeeratorGuardService]},
  {component: ContractsComponent, path: 'contracts', canActivate: [AuthGuardService]},
  {component: ContractsComponent, path: 'contracts/archived', canActivate: [AuthGuardService]},
  {component: ContractDetailsComponent, path: 'contracts/:id', canActivate: [AuthGuardService]},
  {component: PreCreateContractComponent, path: 'contracts/:id/scales', canActivate: [AuthGuardService, ScaleGuardService]},
  {component: EditComponent, path: 'contracts/:id/edit', canActivate: [AuthGuardService]},
  {component: GuardInfoComponent, path: 'contracts/:id/hr/people', canActivate: [AuthGuardService, HrGuardService]},
  {component: GuardEditComponent, path: 'contracts/:id/hr/:idGuard/guard/edit', canActivate: [AuthGuardService]},

  {component: ListGuardsComponent, path: 'contracts/:id/hr/people/edit', canActivate: [AuthGuardService, HrGuardService]},
  {component: InventoryInfoComponent, path: 'contracts/:id/hr/inventory', canActivate: [AuthGuardService, HrGuardService]},
  {component: ListInventoryComponent, path: 'contracts/:id/hr/inventory/edit', canActivate: [AuthGuardService, HrGuardService]},
  {component: AccountingEditComponent, path: 'contracts/:id/accounting', canActivate: [AuthGuardService,AccountingFuardService]},
  {component: HrFlowComponent, path: 'contracts/:id/hr', canActivate: [AuthGuardService, HrGuardService]},
  {component: SotEditComponent, path: 'contracts/:id/sot-operators', canActivate: [AuthGuardService, SotOpeeratorGuardService]},
  {component: NoPermissionComponent, path: 'no-permission'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
