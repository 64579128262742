import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractService } from '../services/contract.service';

@Component({
  selector: 'app-hr-flow',
  templateUrl: './hr-flow.component.html',
  styleUrls: ['./hr-flow.component.css']
})
export class HrFlowComponent implements OnInit {

  id;
  contract;
  constructor(private route: ActivatedRoute, private router: Router, 
    private contractService: ContractService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.contractService.getContract(this.id).subscribe(data => {
      this.contract = data
    })
  }

  renderExcel() {
    this.router.navigate(['create-exel'])
  }

}
