import { Component, OnInit, Input } from '@angular/core';
import { ContractService } from '../services/contract.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-list-inventory',
  templateUrl: './list-inventory.component.html',
  styleUrls: ['./list-inventory.component.css']
})
export class ListInventoryComponent implements OnInit {
  @Input('items')
  items;
  id
  constructor(private contractService: ContractService, 
    private route: ActivatedRoute) { 
    if (!this.items) {
      this.id = this.route.snapshot.paramMap.get("id");

      this.contractService.getContract(this.id).subscribe(data => {
        this.items = data['inventories']
      })
    }
  }

  ngOnInit() {
  }

  deleteInventory(id) {
    this.contractService.deleteInventory( id).subscribe(data => {
      console.log(data);
      location.reload()
    })

  }

}
