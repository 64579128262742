import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ContractService } from '../services/contract.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sot-edit',
  templateUrl: './sot-edit.component.html',
  styleUrls: ['./sot-edit.component.css']
})
export class SotEditComponent {
  contract;
  contractForm: FormGroup;
  user_role: string;
  id: string;

  constructor(private contractService: ContractService, 
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) { 
      this.user_role = localStorage.getItem('role');
      this.id = this.route.snapshot.paramMap.get("id")
      this.contractService.getContract(this.id).subscribe(data => {
        this.contract = data;
        this.contractForm = this.formBuilder.group({
          'income_sot': [this.contract.income_sot,],
          // 'full_set_sot_count': [this.contract.full_set_sot_count,],
          // 'price': [this.contract.contract]
        })
      })
    
  }

  sendContract() {
    console.log(this.contractForm.value)
    this.contractService.editContract(this.id, this.contractForm.value).subscribe(a => {

    })
  }
}